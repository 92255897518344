import React, { useState } from "react";
import BunniesAI from "./../assets/Bunnies-AI/profile.png";
import { FaRegCopy } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
// import { FaTiktok } from "react-icons/fa";
import { CiYoutube } from "react-icons/ci";
import { FaTelegramPlane } from "react-icons/fa";

import Modal from "./Model";
import ModelButton from "./ModelButton";
import { Link } from "react-router-dom";
const BannerSection = () => {
  const [copied, setCopied] = useState(false);
  const contractAddress = "AuTHEbrT36JC7NgejrDZDTtds8fLGp7MR9v6CpLBTqUF";
  const [isModalOpen, setModalOpen] = useState(false);
  const [buttonOpen, setButtonOpen] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(contractAddress);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  const openModal = () => {
    setModalOpen(!isModalOpen);
  };
  const openButtonModal = () => {
    setButtonOpen(!buttonOpen);
  };
  const closeModal = () => setModalOpen(false);
  const closeButtonModal = () => setButtonOpen(false);
  // console.log(isModalOpen, "====================>");
  return (
    <section className="banner_section">
      {/* container start */}
      <div className="container">
        {/* row start */}
        <div className="row">
          {/* shape animation */}
          <span className="banner_shape1">
            <img src="images/banner-shape1.png" alt="Shape 1" />
          </span>
          <span className="banner_shape2">
            <img src="images/banner-shape2.png" alt="Shape 2" />
          </span>
          <span className="banner_shape3">
            <img src="images/banner-shape3.png" alt="Shape 3" />
          </span>

          <div className="col-lg-6 col-md-12">
            {/* banner text */}
            <div className="banner_text">
              <h1>
                Welcome to <br /> Bunnies AI
              </h1>
              <h1 className="address-contact">Contract address :</h1>
              <div className="banner_address">
                <p style={copied ? { color: "white" } : { color: "" }}>
                  {contractAddress}
                </p>
                <FaRegCopy
                  onClick={handleCopy}
                  style={copied ? { color: "#3ea20c" } : { color: "" }}
                />
              </div>
            </div>

            {/* app buttons */}
            {/* <ul className="app_btn lp-token-section"> */}
            <ul className="banner-app-btn-container">
             
              <li className="">
                <Link onClick={openModal} className="leader-game-btn">
                  <BuyBTNSvg />
                  <p>Buy BAI</p>
                </Link>
              </li>
              <li className="">
                <a onClick={openButtonModal} className="play-game-btn">
                  <PlayBTNSvg />
                  <p>Play </p>
                </a>
              </li>
             
            </ul>

            <ul className="navbar-nav ml-auto  banner-menues-icons">
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="https://x.com/bunniesaicom?s=21"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaXTwitter size={20} color="white" />
                </a>
              </li>

              <li className="nav-item">
                <a
                  className="nav-link"
                  href="https://www.youtube.com/@bunniesaiyt"
                  target="_blank"
                  rel="noreferrer"
                >
                  <CiYoutube size={28} color="white" />
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="https://t.me/bunniesai"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaTelegramPlane size={28} color="white" />
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="https://www.instagram.com/bunniesai_com/?igsh=MWtmb3cybm4yMmU4OA%3D%3D&utm_source=qr"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaInstagram size={25} color="white" />
                </a>
              </li>
              {/* <li className="nav-item">
                <a
                  className="nav-link"
                  href="https://www.tiktok.com/@bunniesai"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaTiktok size={20} color="white" />
                </a>
              </li> */}
            </ul>

            {/* users */}
          </div>

          {/* banner slides start */}
          <div className="col-lg-6 col-md-12">
            <div className="banner_image">
              <img
                className="moving_animation"
                src={BunniesAI}
                width={"100%"}
                alt="Banner"
              />
            </div>
          </div>
        </div>
      </div>

      <Modal isOpen={isModalOpen} onClose={closeModal} />
      <ModelButton isOpen={buttonOpen} onClose={closeButtonModal} />

      {/* wave animation start */}
      <div>
        <svg
          className="waves"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 24 150 28"
          preserveAspectRatio="none"
          shapeRendering="auto"
        >
          <defs>
            <path
              id="gentle-wave"
              d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
            />
          </defs>
          <g className="parallax">
            <use
              xlinkHref="#gentle-wave"
              x="48"
              y="0"
              fill="rgba(255,255,255,0.7"
            />
            <use
              xlinkHref="#gentle-wave"
              x="48"
              y="3"
              fill="rgba(255,255,255,0.5)"
            />
            <use
              xlinkHref="#gentle-wave"
              x="48"
              y="5"
              fill="rgba(255,255,255,0.3)"
            />
            <use xlinkHref="#gentle-wave" x="48" y="7" fill="#f6f4fe" />
          </g>
        </svg>
      </div>
      {/* wave animation end */}
    </section>
  );
};

export default BannerSection;

export const PlayBTNSvg = () => {
  return (
    <svg
      width="263"
      height="136"
      viewBox="0 0 263 136"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="35"
        y="31"
        width="193"
        height="73"
        rx="36.5"
        stroke="#F78D96"
        stroke-width="2"
      />
      <g filter="url(#filter0_f_1241_751)">
        <ellipse cx="76.5" cy="68" rx="42.5" ry="34" fill="#F78D96" />
      </g>
      <g filter="url(#filter1_f_1241_751)">
        <ellipse cx="76.5" cy="68" rx="42.5" ry="34" fill="#F78D96" />
      </g>
      <g filter="url(#filter2_f_1241_751)">
        <ellipse cx="186.5" cy="68" rx="42.5" ry="34" fill="#F78D96" />
      </g>
      <g filter="url(#filter3_f_1241_751)">
        <ellipse cx="186.5" cy="68" rx="42.5" ry="34" fill="#F78D96" />
      </g>
      <g filter="url(#filter4_d_1241_751)">
        <rect x="42" y="38" width="179" height="60" rx="30" fill="#F78D96" />
      </g>
      <defs>
        <filter
          id="filter0_f_1241_751"
          x="0.5"
          y="0.5"
          width="152"
          height="135"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="16.75"
            result="effect1_foregroundBlur_1241_751"
          />
        </filter>
        <filter
          id="filter1_f_1241_751"
          x="23"
          y="23"
          width="107"
          height="90"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="5.5"
            result="effect1_foregroundBlur_1241_751"
          />
        </filter>
        <filter
          id="filter2_f_1241_751"
          x="110.5"
          y="0.5"
          width="152"
          height="135"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="16.75"
            result="effect1_foregroundBlur_1241_751"
          />
        </filter>
        <filter
          id="filter3_f_1241_751"
          x="132.7"
          y="22.7"
          width="107.6"
          height="90.6"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="5.65"
            result="effect1_foregroundBlur_1241_751"
          />
        </filter>
        <filter
          id="filter4_d_1241_751"
          x="31.8"
          y="31.8"
          width="199.4"
          height="80.4"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="5.1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.968627 0 0 0 0 0.552941 0 0 0 0 0.588235 0 0 0 0.5 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1241_751"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1241_751"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
export const BuyBTNSvg = () => {
  return (
    <svg
      width="263"
      height="136"
      viewBox="0 0 263 136"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="35"
        y="31"
        width="193"
        height="73"
        rx="36.5"
        stroke="#E6B02E"
        stroke-width="2"
      />
      <g filter="url(#filter0_f_1243_752)">
        <ellipse cx="76.5" cy="68" rx="42.5" ry="34" fill="#E6B02E" />
      </g>
      <g filter="url(#filter1_f_1243_752)">
        <ellipse cx="76.5" cy="68" rx="42.5" ry="34" fill="#E6B02E" />
      </g>
      <g filter="url(#filter2_f_1243_752)">
        <ellipse cx="186.5" cy="68" rx="42.5" ry="34" fill="#E6B02E" />
      </g>
      <g filter="url(#filter3_f_1243_752)">
        <ellipse cx="186.5" cy="68" rx="42.5" ry="34" fill="#E6B02E" />
      </g>
      <g filter="url(#filter4_d_1243_752)">
        <rect x="42" y="38" width="179" height="60" rx="30" fill="#E6B02E" />
      </g>
      <defs>
        <filter
          id="filter0_f_1243_752"
          x="0.5"
          y="0.5"
          width="152"
          height="135"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="16.75"
            result="effect1_foregroundBlur_1243_752"
          />
        </filter>
        <filter
          id="filter1_f_1243_752"
          x="23"
          y="23"
          width="107"
          height="90"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="5.5"
            result="effect1_foregroundBlur_1243_752"
          />
        </filter>
        <filter
          id="filter2_f_1243_752"
          x="110.5"
          y="0.5"
          width="152"
          height="135"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="16.75"
            result="effect1_foregroundBlur_1243_752"
          />
        </filter>
        <filter
          id="filter3_f_1243_752"
          x="132.7"
          y="22.7"
          width="107.6"
          height="90.6"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="5.65"
            result="effect1_foregroundBlur_1243_752"
          />
        </filter>
        <filter
          id="filter4_d_1243_752"
          x="31.8"
          y="31.8"
          width="199.4"
          height="80.4"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="5.1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.901961 0 0 0 0 0.690196 0 0 0 0 0.180392 0 0 0 0.5 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1243_752"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1243_752"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
