import React from "react";
import { FaRegFilePdf } from "react-icons/fa";
import { FaPhoneVolume } from "react-icons/fa";

const Footer = () => {
  return (
    <footer>
      <div className="top_footer" id="contact">
        <div className="container">
          <div className="row footer-menus-row">
            {/* footer link 1 */}
            <div className="col-lg-4 col-md-12 col-12">
              <div className="abt_side w-100 d-flex justify-content-center align-items-center">
                <div className="logo">
                  <a className="navbar-brand bunnies-logo footer-logo" href="#">
                    Bunnies AI
                  </a>
                </div>
              </div>
            </div>

            {/* footer link 4 */}
            <div className="col-lg-4 col-md-12 col-12 ">
              <div className="try_out">
                <ul className="app_btn footer-menus ">
                  <li>
                    <a
                      href="images/BunniesAIsecondPDF.html"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FaRegFilePdf />
                      Bunnies paper 2.2
                    </a>
                  </li>

                  <li className="m-0">
                    <a
                      href="mailto:info@bunniesai.com
"
                    >
                      <FaPhoneVolume />
                      Contact us
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 col-12 ">
              <ul className="footer-location-menus">
                <li>Location</li>
                <li>Ōkunoshima</li>
              </ul>
            </div>
          </div>
          {/* row end */}
        </div>
        {/* container end */}
      </div>
    </footer>
  );
};

export default Footer;
