import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import LearnModern from "../../components/LearnModern";
import LearnAboutSection from "../../components/LearnAboutSection";

const Learn = () => {
  return (
    <div>
      <Header Classprop={"header-secondary"} />
      <div className="learn-section-wrapper">
        <LearnModern />
        <LearnAboutSection />
      </div>
      <Footer />
    </div>
  );
};

export default Learn;
