import React from 'react';
import BunniesThree from './../assets/Bunnies-AI/BunniesThree.png';

const ModernUISection = () => {
  return (
    <section className="row_am modern_ui_section">
      {/* container start */}
      <div className="container">
        {/* row start */}
        <div className="row">
          <div className="col-lg-6">
            {/* UI content */}
            <div className="ui_text">
              <div className="section_title">
                <h2>
                Continuous  <span>Updates</span>
                </h2>
                <p>
                Stay excited with regular updates that introduce new features, characters, mini-games, and special events. Our commitment to continuous improvement means there's always something fresh and exciting in Bunnies AI!
                </p>
              </div>
           
            </div>
          </div>
          <div className="col-lg-6">
            {/* UI Image */}
            <div className="ui_images">
              <div className="left_img">
                <img className="moving_position_animatin" src={BunniesThree} width={'100%'}  alt="Modern UI 1" />
              </div>
            
            </div>
          </div>
        </div>
        {/* row end */}
      </div>
      {/* container end */}
    </section>
  );
};

export default ModernUISection;
