import React, { useState } from "react";
import { BsArrowUpRightCircle } from "react-icons/bs";
import { Link } from "react-router-dom";

const Header = ({ Classprop }) => {
  const [state, setState] = useState(true);

  const handleClick = () => {
    setState(!state);
  };
  return (
    <header className={Classprop}>
      {/* container start */}
      <div className="container">
        {/* navigation bar */}
        <nav className="navbar navbar-expand-lg">
          <Link className="navbar-brand bunnies-logo" to="/">
            {/* <img src="images/logo.png" alt="Logo" /> */}
            Bunnies AI
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded={state}
            aria-label="Toggle navigation"
            onClick={handleClick}
          >
            <span className="navbar-toggler-icon">
              <div className={`toggle-wrap ${state ? "" : "active"}`}>
                <span className="toggle-bar"></span>
              </div>
            </span>
          </button>
          {/* add show class */}
          <div
            className={`collapse navbar-collapse  ${state ? "" : "show"}`}
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="https://solscan.io/tx/21jDCg9cd2pHRhntmaoMgAQMVEHpNiT3fhha4LS22MwZxF5hx5Vxa97hSrc582rhUcZ19CaXdPDYsnfWR3pmTkQR"
                  target="_blank"
                  rel="noreferrer"
                >
                  {/* <FaTiktok  size={20} /> */}100% of the LP tokens are burned
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="images/BunniesAIsecondPDF.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  {/* <CiYoutube  size={28}/> */}Bunnies paper 2.2
                </a>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                 
                  to="/leaderboard"
                  
                >
                  Leaderboard
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link dark_btn" to="/learn">
                  Learn More <BsArrowUpRightCircle size={52} className="ml-3" />
                </Link>
              </li>
            </ul>
          </div>
        </nav>
        {/* navigation end */}
      </div>
      {/* container end */}
    </header>
  );
};

export default Header;
