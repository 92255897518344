import React from "react";
import BunniesOne from "./../assets/Bunnies-AI/BunniesOne.png";

const AboutAppSection = () => {
  return (
    <section className="row_am about_app_section">
      {/* container start */}
      <div className="container">
        {/* row start */}
        <div className="row">
          <div className="col-lg-6">
            {/* about images */}
            <div className="about_img">
              <div className="frame_img">
                <img
                  className="moving_position_animatin"
                  src={BunniesOne}
                  alt="Frame"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6 align-self-center">
            {/* about text */}
            <div className="about_text">
              <div className="section_title">
                {/* h2 */}
                <h2>
                  Why <span>Bunnies AI?</span>
                </h2>
                {/* p */}
                <p>
                  We’re a vibrant Tap-to-Earn game community on Telegram, where
                  earning tokens is just the beginning! Engage in a wide range
                  of activities, connect with other players, and see your
                  efforts rewarded through our innovative system. Every moment
                  you spend with us is designed to be valuable and rewarding.{" "}
                  <br />
                  You can even earn real money by playing, but you’ll need to
                  rank in the top 10 to cash out! Every Friday, we reward the
                  top 10 players of the week—it’s our way of recognizing and
                  celebrating the dedication and skills of our community
                  members. Climb to the top, and you could be one of the lucky
                  winners each week! <br />
                  We manually transfer USDT (Tether) directly to your account,
                  ensuring secure and reliable payments. Our team handles every
                  transaction personally, making sure you receive your rewards
                  accurately and on time.
                </p>
              </div>

              <a href="/learn" className="btn puprple_btn">
                Learn More
              </a>
            </div>
          </div>
        </div>
        {/* row end */}
      </div>
      {/* container end */}
    </section>
  );
};

export default AboutAppSection;
