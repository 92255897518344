import React from "react";
// import BunniesThree from "./../assets/Bunnies-AI/BunniesTwo.png";
import BunniesLast from "./../assets/Bunnies-AI/bunneslast.jpg";
import BunniesEleven from "./../assets/Bunnies-AI/BunniesEleven.png";

const LearnAboutSection = () => {
  return (
    <section className="row_am modern_ui_section">
      {/* container start */}
      <div className="container">
        {/* row start */}
        <div className="row learn-about-section">
          <div className="col-lg-6">
            {/* UI content */}
            <div className="ui_text">
              <div className="section_title">
                <h2>
                  Join Us on <span>Our Journey</span>
                </h2>
              </div>
              <ul className="design_block">
                <li>
                  <h4>Be a Part of the Future of Gaming with Bunnies AI</h4>
                  <p>
                    At Bunnies AI, we're not just creating a game; we're
                    building a community and revolutionizing the way people
                    experience gaming.
                  </p>
                </li>
                <li>
                  <h4>Get in Touch</h4>
                  <p>
                    Have questions or ideas? Want to collaborate with us? We’d
                    love to hear from you! Connect with us on social media, join
                    our forums, or send us an email. <br />
                    Welcome to BunnyVerse, where every bunny belongs!
                  </p>
                </li>
              </ul>
            </div>
          </div>
          {/* <div className="col-lg-6 image-section-container">
          
            <div className=" modern-image-ui-section">
              <div className="left_img">
                <img
                  className="moving_position_animatin"
                  src={BunniesLast}
                  width={"100%"}
                  alt="Modern UI 1"
                />
              </div>
            </div>
          </div> */}
          <div className="col-lg-6">
            {/* UI Image */}
            <div className="ui_images">
              <div className="left_img">
                <img
                  className="moving_position_animatin"
                  src={BunniesEleven}
                  width={"100%"}
                  alt="Modern UI 1"
                />
              </div>
            </div>
          </div>
        </div>
        {/* row end */}
      </div>
      {/* container end */}
    </section>
  );
};

export default LearnAboutSection;
