import React from "react";
import BunniesSix from './../assets/Bunnies-AI/BunniesSix.png'
import BunniesThree from './../assets/Bunnies-AI/BunniesThree.png'
import BunniesTwo from './../assets/Bunnies-AI/BunniesAI.png'
import BunniesbannerSecond from './../assets/Bunnies-AI/Bunnies-banner-second.png'

const HowItWorks = () => {
  return (
    <section className="row_am how_it_works" id="how_it_work">
      {/* container start */}
      <div className="container">
        <div className="how_it_inner">
          <div className="section_title">
            <h2>
              <span>What to Expect-</span> Adorable Bunny Characters
            </h2>
            <p>
            Meet and customize your own bunny avatars, each with unique 
              <br /> personalities and charming features.
            </p>
          </div>

          <div className="step_block">
            {/* Steps */}
            <ul>
              {/* Step 1 */}
              <li>
                <div className="step_text">
                  <h4>Engaging "Tap to Earn" Gameplay:</h4>
          
                  <p>Experience the addictive thrill of earning tokens simply by tapping on the screen. The more you tap, the more you earn, making every moment exciting and rewarding.</p>
                </div>
                <div className="step_number">
                  <h3>01</h3>
                </div>
                <div className="step_img">
                  <img src={BunniesSix} alt="Download App" />
                </div>
              </li>

              {/* Step 2 */}
              <li>
                <div className="step_text">
                  <h4>Exciting Mini-Games:</h4>
                 
                  <p>
                  Dive into a variety of mini-games that offer unique challenges and additional ways to earn tokens. These mini-games add depth and variety to the gameplay, ensuring you always have something fun to do.
                  </p>
                </div>
                <div className="step_number">
                  <h3>02</h3>
                </div>
                <div className="step_img">
                  <img src={BunniesbannerSecond} alt="Create Account" />
                </div>
              </li>

              {/* Step 3 */}
              <li>
                <div className="step_text">
                  <h4>Customizable Characters:</h4>
               
                  <p>
                  Personalize your bunnies with a wide range of outfits and accessories. Customize their appearance to reflect your style and enhance their abilities to improve your gameplay experience.
                  </p>
                </div>
                <div className="step_number">
                  <h3>03</h3>
                </div>
                <div className="step_img">
                  <img src={BunniesTwo} alt="Enjoy the App" />
                </div>
              </li>
             
             
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
