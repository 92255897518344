import React from "react";
import BunniesTen from "./../assets/Bunnies-AI/bunnesTen.png";
const LearnModern = () => {
  return (
    <section className="row_am modern_ui_section our_vision-section">
      {/* container start */}
      <div className="container">
        {/* row start */}
        <div className="row">
          <div className="col-lg-6">
            {/* UI content */}
            <div className="ui_text">
              <div className="section_title">
                <h2>
                  Our <span>Vision</span>
                </h2>
              </div>
              <ul className="design_block">
                <li>
                  <h4>Empowering Players through Innovation and Community</h4>
                  <p>
                    At Bunnies AI, our vision is to create a revolutionary
                    gaming experience that combines the excitement of "Tap to
                    Earn" mechanics with the power of blockchain technology,
                    fostering a vibrant community of players who are engaged,
                    rewarded, and empowered.
                  </p>
                </li>
                <li>
                  <h4>Empowering Players with Real Value:</h4>
                  <p>
                    Bunnies AI is designed to provide real value to its players.
                    Our innovative rewards system allows players to earn
                    tangible benefits through gameplay, character customization,
                    and participation in events. By bridging the gap between
                    virtual achievements and real-world rewards, we empower our
                    players to truly benefit from their time spent in the game.
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-6">
            {/* UI Image */}
            <div className="ui_images">
              <div className="left_img">
                <img
                  className="moving_position_animatin"
                  src={BunniesTen}
                  width={"100%"}
                  alt="Modern UI 1"
                />
              </div>
            </div>
          </div>
        </div>
        {/* row end */}
      </div>
      {/* container end */}
    </section>
  );
};

export default LearnModern;
