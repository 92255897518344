import Home from "./pages/home";
import LeaderBoard from "./pages/leaderBoard/LeaderBoard";
import Learn from "./pages/learn";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function App() {
  return (
    <div className="page_wrapper">
      
      <BrowserRouter>
        <Routes>
          <Route index element={<Home />} />
          <Route path="learn" element={<Learn />} />
          <Route path="leaderboard" element={<LeaderBoard />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
