import React from 'react';
import Header from '../../components/Header';
import BannerSection from '../../components/BannerSection';
// import FeaturesSection from '../../components/FeaturesSection';
import AboutAppSection from '../../components/AboutAppSection';
import ModernUISection from '../../components/ModernUISection';
import HowItWorks from '../../components/HowItWorks';

import Footer from '../../components/Footer';

const Home = () => {
    return (
        <div>
           <Header/>
           <BannerSection/>
           <AboutAppSection/>
           <ModernUISection/>
           <HowItWorks/>
           {/* <FeaturesSection/> */}
        
           <Footer/>
        </div>
    );
}

export default Home;
