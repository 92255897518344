import React, { useState } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import LeaderBoardSlider from "../../components/LeaderBoardSlider";

import ModelButton from "../../components/ModelButton";

const LeaderBoard = () => {
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(!isModalOpen);
  };

  const closeModal = () => setModalOpen(false);

  return (
    <div>
      <Header Classprop={"header-secondary"} />
      <div className="leaderboard">
        <section className="banner_section">
          <ul
            className="banner-app-btn-container"
            style={{
              justifyContent: "center",
              marginTop: "0px",
              marginBottom: "-20px",
            }}
          >
            <li className="">
              <a onClick={openModal} className="play-game-btn">
                <PlayBTNSvg />
                <p>Play </p>
              </a>
            </li>
          </ul>

          <ModelButton isOpen={isModalOpen} onClose={closeModal} />
        </section>

        <h1 className="leaderboard-title"> Leaderboard</h1>

        <LeaderBoardSlider />
      </div>
      {/* <ModelButton isOpen={buttonOpen} onClose={closeButtonModal} /> */}
      <Footer />
    </div>
  );
};

export default LeaderBoard;

export const PlayBTNSvg = () => {
  return (
    <svg
      width="263"
      height="136"
      viewBox="0 0 263 136"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="35"
        y="31"
        width="193"
        height="73"
        rx="36.5"
        stroke="#F78D96"
        stroke-width="2"
      />
      <g filter="url(#filter0_f_1241_751)">
        <ellipse cx="76.5" cy="68" rx="42.5" ry="34" fill="#F78D96" />
      </g>
      <g filter="url(#filter1_f_1241_751)">
        <ellipse cx="76.5" cy="68" rx="42.5" ry="34" fill="#F78D96" />
      </g>
      <g filter="url(#filter2_f_1241_751)">
        <ellipse cx="186.5" cy="68" rx="42.5" ry="34" fill="#F78D96" />
      </g>
      <g filter="url(#filter3_f_1241_751)">
        <ellipse cx="186.5" cy="68" rx="42.5" ry="34" fill="#F78D96" />
      </g>
      <g filter="url(#filter4_d_1241_751)">
        <rect x="42" y="38" width="179" height="60" rx="30" fill="#F78D96" />
      </g>
      <defs>
        <filter
          id="filter0_f_1241_751"
          x="0.5"
          y="0.5"
          width="152"
          height="135"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="16.75"
            result="effect1_foregroundBlur_1241_751"
          />
        </filter>
        <filter
          id="filter1_f_1241_751"
          x="23"
          y="23"
          width="107"
          height="90"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="5.5"
            result="effect1_foregroundBlur_1241_751"
          />
        </filter>
        <filter
          id="filter2_f_1241_751"
          x="110.5"
          y="0.5"
          width="152"
          height="135"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="16.75"
            result="effect1_foregroundBlur_1241_751"
          />
        </filter>
        <filter
          id="filter3_f_1241_751"
          x="132.7"
          y="22.7"
          width="107.6"
          height="90.6"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="5.65"
            result="effect1_foregroundBlur_1241_751"
          />
        </filter>
        <filter
          id="filter4_d_1241_751"
          x="31.8"
          y="31.8"
          width="199.4"
          height="80.4"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="5.1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.968627 0 0 0 0 0.552941 0 0 0 0 0.588235 0 0 0 0.5 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1241_751"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1241_751"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
