import React, { useEffect, useState, useCallback } from "react";
import Profile from "./../assets/Bunnies-AI/profile.png";
import axios from "axios";
import Profile_1 from "./../assets/Bunnies-AI/Leaderboard/1.jpg";
import Profile_2 from "./../assets/Bunnies-AI/Leaderboard/2.jpg";
import Profile_3 from "./../assets/Bunnies-AI/Leaderboard/3.jpg";
import Profile_4 from "./../assets/Bunnies-AI/Leaderboard/4.jpg";
import Profile_5 from "./../assets/Bunnies-AI/Leaderboard/5.jpg";
import Profile_6 from "./../assets/Bunnies-AI/Leaderboard/6.jpg";
import Profile_7 from "./../assets/Bunnies-AI/Leaderboard/7.jpg";
import Profile_8 from "./../assets/Bunnies-AI/Leaderboard/8.jpg";
import Profile_9 from "./../assets/Bunnies-AI/Leaderboard/9.jpg";
import Profile_10 from "./../assets/Bunnies-AI/Leaderboard/10.jpg";
import Profile_11 from "./../assets/Bunnies-AI/Leaderboard/11.jpg";




 


const LevelBronze = ({ bronzeName, bronzeValue, levelNumber, gradient }) => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1); // Track the current page
  const [hasMore, setHasMore] = useState(true); // Flag to indicate if more data is available

  const fetchUsers = useCallback(async () => {
    if (loading || !hasMore) return;

    setLoading(true);
    try {
      const resp = await axios.post(
        "https://api.bunniesai.com/v1/users/getUsersByLevel",
        {
          level: levelNumber,
          page, // Use the latest page state
          limit: 10, // Fetch 50 users at a time
        }
      );

      // Merge new users with the existing ones, avoiding duplicates
      setUsers((prev) => {
        const combinedUsers = [...prev, ...resp.data.users];
        const uniqueUsers = Array.from(
          new Map(combinedUsers.map((user) => [user._id, user])).values()
        );

        // If the number of users exceeds 500, sort by tokens and trim the list to 500
        if (uniqueUsers.length > 500) {
          uniqueUsers.sort((a, b) => b.tokens - a.tokens); // Sort by tokens in descending order
          return uniqueUsers.slice(0, 500); // Keep only the top 500 users
        }

        return uniqueUsers;
      });
      setHasMore(resp.data.hasMore); // Update the hasMore flag

      // Increment the page for the next fetch
      setPage((prev) => prev + 1);
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
    }
  }, [hasMore, loading, levelNumber, page]);

  useEffect(() => {
    // Initial fetch when component mounts
    fetchUsers();
  }, []);

  useEffect(() => {
    // Stop fetching if users length reaches 500
    if (users.length >= 500) {
      setHasMore(false);
    }
  }, [users]);


  const formatToK = (value) => {
    if (typeof value !== "number" || !isFinite(value)) {
      return "Invalid value";
    }
    if (value >= 1000 && value < 1000000) {
      return (value / 1000).toFixed(1) + "K";
    } else if (value >= 1000000) {
      return (value / 1000000).toFixed(1) + "M";
    }
    return value.toFixed();
  };

  const profileImages = {
    1: Profile_1,
    2: Profile_2,
    3: Profile_3,
    4: Profile_4,
    5: Profile_5,
    6: Profile_6,
    7: Profile_7,
    8: Profile_8,
    9: Profile_9,
    10: Profile_10,
    11: Profile_11,
  };

  const imageSrc = profileImages[levelNumber] || Profile

  return (
    <div
      className="level_bronze"
      style={{
        background: gradient,
        backgroundSize: "300%",
        backgroundPosition: "center",
      }}
    >
      <div
        className={`level_bronze_img-container ${
          users.length === 0 ? "level_bronze_img-conditional" : ""
        }`}
        style={{
          background: gradient,
          backgroundPosition: "center",
          backgroundSize: "140%",
        }}
      >
        <div className="level_bronze_img">
          <img src={imageSrc} alt="Profile" />
        </div>
        <div className="level-info">
          <h3>{bronzeName === 'Legendry'? 'Legendary' : bronzeName}</h3>
          <p>{bronzeValue}</p>
        </div>
      </div>

      <div
        className="level_bronze_line-container"
        style={{ background: gradient }}
      >
        {users.map((user, index) => (
          <div className="level_bronze_line" key={index}>
            <div className="left">
              <div className="line-image">
                <img src={user?.profileImage || Profile} alt="" />
              </div>
              <div className="line-info">
                <h3>{user.name}</h3>
                <p>{formatToK(user.tokens)}</p>
              </div>
            </div>
            <div className="right">
              <h2>{index + 1}</h2>
            </div>
          </div>
        ))}
      </div>
      {loading ? 
      <div className="loader-container"> 

        <span className="loader_load_more"></span>
      </div>
       :  
      (hasMore && (
        <div className="load-more-button">
          <button className="mx-auto" onClick={() => fetchUsers()}>
            Load More
          </button>
        </div>
      ))
      }
      {/* {loading && <p>Loading...</p>} */}
    </div>
  );
};

export default LevelBronze;
