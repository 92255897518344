import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import LevelBronze from "./LevelBronze";
import axios from "axios";
import { FaChevronLeft } from "react-icons/fa";
import { FaChevronRight } from "react-icons/fa";

function LeaderBoardSlider() {
  const [levels, setLevels] = useState([]); // Initialize with an empty array
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchLevels = async () => {
      try {
        const { data } = await axios.get(
          "https://api.bunniesai.com/v1/users/levels"
        );
        const sortedLevels = data.levels.sort((a, b) => a.levelNo - b.levelNo);
        // console.log(sortedLevels);
        setLevels(sortedLevels);
      } catch (err) {
        console.error("Error fetching levels:", err);
        setError("Failed to load levels.");
      } finally {
        setLoading(false);
      }
    };

    fetchLevels();
  }, []);

  // Custom Next Arrow for Slider
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className="right_arrow-icon"
        style={{
          ...style,
          // display: "block",
          borderRadius: "50%",
        }}
        onClick={onClick}
      >
        <FaChevronRight size={40} />
      </div>
    );
  }

  // Custom Previous Arrow for Slider
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className="left_arrow-icon"
        style={{
          ...style,
          display: "block",

          borderRadius: "50%",
        }}
        onClick={onClick}
      >
        <FaChevronLeft size={40} />
      </div>
    );
  }

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    // slidesToScroll: 1,
    // autoplay: true,
    speed: 500,
    // autoplaySpeed: 3000,
    className: "center",
    // className: "center",
    centerMode: true,
    centerPadding: "350px",
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1120,
        settings: {
          centerPadding: "280px",
        },
      },
      {
        breakpoint: 1024,
        settings: {
          centerPadding: "200px",
        },
      },
      {
        breakpoint: 850,
        settings: {
          centerPadding: "100px",
        },
      },
      {
        breakpoint: 768,
        settings: {
          centerPadding: "100px",
        },
      },
      {
        breakpoint: 650,
        settings: {
          centerPadding: "50px",
        },
      },
      {
        breakpoint: 600,
        settings: {
          centerPadding: "0px",
        },
      },
    ],
  };

  if (loading) {
    return (
      // <div class="page_wrapper">
      <div id="preloader" style={{ background: "#101010" }}>
        <div id="loader"></div>
      </div>
      // </div>
    );
  }

  if (error) {
    return <div>{error}</div>; // Error message if API call fails
  }

  const gradients = [
    "radial-gradient(circle, rgba(205, 127, 50, 0.8), rgba(139, 69, 19, 0.18), rgba(110, 54, 19, 0))",
    "radial-gradient(circle, rgba(192, 192, 192, 0.8), rgb(169 169 169 / 18%), rgba(128, 128, 128, 0))",
    "radial-gradient(circle, rgba(255, 215, 0, 0.8), rgba(218, 165, 32, 0.18), rgba(184, 134, 11, 0))",
    "radial-gradient(circle, rgba(221, 239, 255, 0.9), rgba(187, 222, 251, 0.25), rgba(156, 204, 235, 0))",

    "radial-gradient(circle, rgba(173, 216, 230, 0.8), rgba(100, 149, 237, 0.18), rgba(70, 130, 180, 0))",
    "radial-gradient(circle, rgba(144, 238, 144, 0.8), rgba(60, 179, 113, 0.18), rgba(34, 139, 34, 0))",
    "radial-gradient(circle, rgba(255, 182, 193, 0.8), rgba(255, 160, 122, 0.18), rgba(255, 105, 97, 0))",
    "radial-gradient(circle, rgba(255, 182, 193, 0.8), rgba(255, 105, 180, 0.18), rgba(255, 192, 203, 0))",

    // new colors
    "radial-gradient(circle, rgba(255, 228, 181, 0.8), rgba(255, 165, 0, 0.18), rgba(255, 140, 0, 0))",
    "radial-gradient(circle, rgba(147, 112, 219, 0.8), rgba(138, 43, 226, 0.18), rgba(75, 0, 130, 0))",
    "radial-gradient(circle, rgba(224, 255, 255, 0.8), rgba(175, 238, 238, 0.18), rgba(0, 206, 209, 0))",
  ];

  return (
    <div className="slider-container">
      {levels.length ? (
        <Slider {...settings}>
          {levels.map((item, index) => (
            <div key={index}>
              <LevelBronze
                bronzeName={item.name}
                bronzeValue={item.value}
                levelNumber={item.levelNo}
                gradient={gradients[index % gradients.length]}
              />
            </div>
          ))}
        </Slider>
      ) : (
        <div>No levels available</div>
      )}
    </div>
  );
}

export default LeaderBoardSlider;
