import React from "react";
// import { FaTelegramPlane } from "react-icons/fa";

import { RxCross2 } from "react-icons/rx";
import Blitz from "././../assets/Bunnies-AI/Blitz.jpeg";
import Teligram from "././../assets/Bunnies-AI/teligram.jpeg";
import { Link } from "react-router-dom";
const ModelButton = ({ isOpen, onClose }) => {
  if (!isOpen) return null; // Modal should only render when open
  return (
    <div className="modal-overlay button-modal" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="close-button" onClick={onClose}>
          <RxCross2 color="#dcdcdc" />
        </button>

        <ul className="app_btn  banner-buy-section">
          <li className="moving_animation_leftRight">
            <a
              href="https://t.me/bunnies_ai_bot"
              target="_blank"
              rel="noreferrer"
              style={{ justifyContent: "start" }}
            >
              <img
                className="blue_img white-img"
                src={Teligram}
                alt="Google Play Blue"
              />
              {/* <FaTelegramPlane size={16} /> */}
              Bunnies AI telegram app
            </a>
          </li>

          <li className="moving_animation_RightLeft">
            <Link to="https://link.bunniesai.com/Bunniesai.zip" download>
              <img className="blue_img" src={Blitz} alt="Google Play Blue" />
              Bunny blitz
            </Link>
          </li>
        </ul>
        <p className="model-password">
          Password: <span>Bunnies AI</span>
        </p>
      </div>
    </div>
  );
};

export default ModelButton;
